<template>
  <div>
    <!-- Table Container Card -->
    <div class="adduser-btn-row w-360 con">
      <h4 class="card-title">
        {{ !isUserShow ? $t("SubAccounts.Consumerlist") : userAccountName }}
      </h4>
      <b-button
        variant="link"
        class="v2-back"
        v-if="!isUserConsumer"
        @click="consumerShow"
        >{{ $t("back.back") }}</b-button
      >
      <b-button
        variant="primary"
        class="btn-icon"
        v-if="!isUserConsumer"
        @click="ShowSentInvitation"
      >
        <feather-icon icon="UserPlusIcon" />
        <span class="d-none d-sm-inline ml-1"
          >{{ $t("ManageAccount.Add") }} {{ $t("Breadcrumb_.Consumer") }}</span
        >
      </b-button>
      <div class="d-flex w-100 justify-content-end">
        <search
          style="float: left"
          class="search-comp btn-icon no-btm-0"
          v-model="searchFiled"
          :searchColumn="searchColumn"
          :operator="operator"
        ></search>
        <span id="addConsumerAccount">
          <b-button
            variant="primary"
            class="btn-icon ml-1"
            @click="redirectOnConsumerScreen"
            v-if="
              !isUserShow && $route.params.account_id
                ? checkAbility({
                    action: constants.PERMISSIONS_ACTION.CONSUMER_ACCOUNT_ADD,
                    subject: constants.PERMISSIONS_MODEL.CONSUMER_ACCOUNT
                  })
                : checkAbility({
                    action: constants.PERMISSIONS_ACTION.CONSUMER_ADD,
                    subject: constants.PERMISSIONS_MODEL.CONSUMER_ACCOUNT
                  })
            "
          >
            <feather-icon icon="UserPlusIcon" />
            <span class="d-none d-sm-inline ml-1"
              >{{ $t("ManageAccount.Add") }}
              {{ $t("Breadcrumb_.Consumer") }}</span
            >
          </b-button>
        </span>
        <b-button
          variant="outline-primary"
          size="sm"
          class="add-user-btn v2-back ml-10"
          @click="redirectOnBack()"
        >
          <feather-icon icon="ArrowLeftIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">{{ $t("role.Back") }}</span>
        </b-button>
      </div>

      <b-tooltip delay="100" variant="primary" target="addConsumerAccount">{{
        $t("tooTip.addConsumerAccount")
      }}</b-tooltip>
    </div>
    <b-card no-body class="mb-0" v-if="!isUserShow">
      <div class="table-responsive" v-if="show">
        <b-skeleton-table
          v-if="show"
          :rows="10"
          :columns="1"
          :table-props="{ bordered: true, striped: true }"
        />
      </div>
      <b-table
        v-if="!show"
        class="position-relative table-responsive no-headers"
        :class="
          $route.name === 'consumer' ? 'dealer-min-650-m' : 'dealer-min-650'
        "
        responsive
        show-empty
        align-v="end"
        :items="items"
        :fields="tableColumns"
        :empty-text="$t('NoMatchingRecordsFound')"
      >
        <!-- Column: User -->
        <template #cell(user)="row">
          <b-media class="align-item-center">
            <template #aside>
              <b-avatar
                size="50"
                src="RA"
                :text="row.item.avtar_txt"
                :variant="row.item.variant"
              />
            </template>
            <h6 class="mb-0">
              {{ row.item.name }}
            </h6>
            <small class="text-muted">{{
              row.item.legal_entity
                ? $t("profile.Business")
                : $t("profile.Personal")
            }}</small>
          </b-media>
        </template>
        <template #cell(action)="row">
          <div class="text-right">
            <feather-icon
              icon="UsersIcon"
              size="18"
              variant="primary"
              class="cursor-pointer"
              v-b-tooltip.hover.top="$t('user.ManageSubAccounts')"
              style="position: relative; margin-right: 15px"
              @click="subConsumerShow(row.item)"
              v-if="
                row.item.status === 'ACTIVE' &&
                !row.item.restrict_sub_accounts &&
                checkAbility({
                  action: constants.PERMISSIONS_ACTION.CONSUMER_ACCOUNT_READ,
                  subject: constants.PERMISSIONS_MODEL.CONSUMER_ACCOUNT
                })
              "
            />
            <span :id="row.item.id">
              <feather-icon
                icon="UserIcon"
                size="18"
                variant="primary"
                v-b-tooltip.hover.top="$t('user.ManageUsers')"
                style="position: relative; margin-right: 15px"
                class="cursor-pointer"
                @click="userShow(row.item)"
                v-if="
                  row.item.status === 'ACTIVE' &&
                  checkAbility({
                    action: constants.PERMISSIONS_ACTION.READ,
                    subject: constants.PERMISSIONS_MODEL.USER_ACCOUNT_CONSUMER
                  })
                "
              />
            </span>
            <b-link
              :to="{
                name: $route.params.account_id
                  ? 'consumer-sub-account-update-details'
                  : 'consumer-account-update-details',
                query: {
                  id: row.item.id,
                  isEdit: true,
                  account_id: $route.params.account_id
                }
              }"
              v-if="
                $route.params.account_id
                  ? checkAbility({
                      action:
                        constants.PERMISSIONS_ACTION
                          .UPDATE_CONSUMER_CHILD_ACCOUNT,
                      subject: constants.PERMISSIONS_MODEL.CONSUMER_ACCOUNT
                    })
                  : checkAbility({
                      action: constants.PERMISSIONS_ACTION.UPDATE_CONSUMER,
                      subject: constants.PERMISSIONS_MODEL.CONSUMER_ACCOUNT
                    })
              "
            >
              <feather-icon
                v-b-tooltip.hover.top="$t('tooTip.update')"
                icon="EditIcon"
                class="text-primary action-icon cursor-pointe"
                size="18"
              />
            </b-link>
            <b-dropdown
              v-if="
                $route.params.account_id
                  ? checkAbility({
                      action:
                        constants.PERMISSIONS_ACTION
                          .UPDATE_CONSUMER_CHILD_ACCOUNT,
                      subject: constants.PERMISSIONS_MODEL.CONSUMER_ACCOUNT
                    })
                  : checkAbility({
                      action: constants.PERMISSIONS_ACTION.UPDATE_CONSUMER,
                      subject: constants.PERMISSIONS_MODEL.CONSUMER_ACCOUNT
                    })
              "
              :text="
                row.item.status
                  ? $t(`device.StatusList.${row.item.status}`)
                  : $t('device.StatusList.ACTIVE')
              "
              id="dropdown-dropright"
              v-model="row.item.status"
              size="sm"
              :variant="
                row.item.status && row.item.status == 'INACTIVE'
                  ? 'danger'
                  : row.item.status && row.item.status == 'BLOCKED'
                  ? 'warning'
                  : 'primary'
              "
              class="m-l-0 m-2 btn-min-width"
            >
              <b-dropdown-item
                @click="updateStatus(row.item, 'ACTIVE')"
                class="btn-min-width"
                >{{ $t("active") }}</b-dropdown-item
              >
              <b-dropdown-item
                @click="updateStatus(row.item, 'INACTIVE')"
                class="btn-min-width"
                >{{ $t("inactive") }}</b-dropdown-item
              >
              <b-dropdown-item
                @click="updateStatus(row.item, 'BLOCKED')"
                class="btn-min-width"
                >{{ $t("blocked") }}</b-dropdown-item
              >
            </b-dropdown>
          </div>
        </template>
      </b-table>
      <div class="mx-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            &nbsp;
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-if="totalConsumer > 0"
              v-model="currentPage"
              :total-rows="totalConsumer"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              :no-paging-nav="false"
              :active-class="activeClass"
              @input="handlePageChange"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <!-- <Loader :show="show" /> -->
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  VBTooltip,
  BSkeletonTable
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import AreYouSureModal from "@/layouts/components/AreYouSureModal.vue";
import AccountService from "@/libs/api/account-service";
import Ripple from "vue-ripple-directive";
import Loader from "@/layouts/components/Loader.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Search from "@/views/Common/search.vue";
import constants from "@/utils/constants";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    Loader,
    // UserListComp,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    AreYouSureModal,
    BTooltip,
    BSkeletonTable,
    VBTooltip,
    Search
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip
  },
  data() {
    return {
      tableColumns: [
        { key: "user", label: this.$t("historyTableColumns.user") },
        { key: "action", label: this.$t("historyTableColumns.action") }
      ],
      totalConsumer: 0,
      user: {},
      perPage: this.$route.query.perPage ? this.$route.query.perPage : 10,
      currentPage: this.$route.query.currentPage
        ? this.$route.query.currentPage
        : 1,
      activeClass: "active",
      items: [],
      show: false,
      isUserShow: false,
      accountId: "",
      isUserConsumer: true,
      userAccountName: "",
      searchColumn: ["name"],
      searchFiled: {},
      filter: {},
      operator: "ilike",
      timeoutId: ""
    };
  },
  props: ["userListShow"],
  mounted() {
    this.$nextTick(() => {
      const { page, filter, perPage } = this.$route.query;

      if (!page) {
        this.updateUrl({ key: "page", value: 1 });
      } else {
        this.currentPage = parseInt(page);
      }

      if (filter) {
        this.filter = filter;
      }

      if (perPage) {
        this.perPage = parseInt(perPage);
      }

      if (
        localStorage.getItem("USER_ACCOUNT_TYPE") === "CONSUMER" &&
        this.$route.name === "dealer"
      ) {
        this.$router.push({ name: "unauthorized" });
      }

      this.debounceGetDealerList();
    });
  },
  watch: {
    perPage() {
      if (!this.perPage) {
        this.perPage = 10;
      }
      this.updateUrl({ key: "perPage", value: this.perPage });
      this.debounceGetDealerList();
    },
    "$route.query.page"(to, from) {
      this.currentPage = to;
    },
    currentPage(newPage) {
      this.debounceGetDealerList();
    },

    $route(to, from) {
      if (to.query && from.query && to.query.filter != from.query.filter) {
      } else {
        this.debounceGetDealerList();
      }
    },
    searchFiled(val) {
      this.filter = val;
      this.debounceGetDealerList();
    }
  },
  methods: {
    redirectOnBack() {
      if (this.$route.query.page == 1) {
        if (
          this.$route.name == "sub_consumer" ||
          this.$route.name == "dealer"
        ) {
          this.$router.go(-3);
        } else {
          this.$router.go(-2);
        }
      } else {
        this.$router.go(-1);
      }
    },
    debounceGetDealerList() {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        this.getConsumer();
      }, 300); // Adjust the delay as necessary
    },
    updateUrl(e) {
      this.$router.push({
        query: { ...this.$route.query, [e.key]: e.value }
      });
    },
    SHOWuSER() {
      this.$bvModal.hide("modal-sm-remove");
    },
    callGetConsumer() {
      if (this.$route && this.$route.query && this.$route.query.tab) {
        if (this.$route.query.tab === "consumer") {
          this.getConsumer();
        }
      } else {
        this.getConsumer();
      }
    },
    ShowSentInvitation() {
      this.$bvModal.show("modal-sm-invitations");
    },
    subConsumerShow(e) {
      this.$router.push({ params: { account_id: e.id }, name: "sub_consumer" });
    },
    async getConsumer() {
      try {
        this.show = true;
        if (!this.filter || !this.filter.value) {
          if (this.$route.query.filter) {
            this.filter = {
              field: "all",
              operator: "ilike",
              value: this.$route.query.filter
            };
          } else {
            this.filter = {};
          }
        }

        let accountIdObj = this.$route.params.account_id
          ? { account_id: this.$route.params.account_id }
          : {};
        store.commit("mapLoader/SET_IS_BREADCRUMB_LOADING", true);
        let response = this.$route.params.account_id
          ? await new AccountService().getSubConsumerList({
              page: this.currentPage,
              type: "CONSUMER",
              page_size: parseInt(this.perPage),
              ...accountIdObj,
              filters:
                this.filter && Object.keys(this.filter).length > 0
                  ? [this.filter]
                  : []
            })
          : await new AccountService().getConsumerList({
              page: this.currentPage,
              type: "CONSUMER",
              page_size: parseInt(this.perPage),
              filters:
                this.filter && Object.keys(this.filter).length > 0
                  ? [this.filter]
                  : []
            });

        if (response && response.data) {
          store.commit("mapLoader/SET_IS_BREADCRUMB_LOADING", false);
          this.show = false;
          this.items = response.data.list || [];
          this.items = this.items.map((u) => {
            u.variant = this.getRandomBgColor();
            u.avtar_txt = this.userAvatarTxt(u.name);
            u.manage_user = `manage_user${u.id}`;
            u.manage_account = `manage_account${u.id}`;
            return u;
          });
          const query = (this.$route.query && this.$route.query.tab) || "";

          if (this.$route.name === "sub_consumer" || query === "consumer") {
            if (
              response.data.newAncestor &&
              response.data.newAncestor.length &&
              this.$route.meta.breadcrumb &&
              this.$route.meta.breadcrumb.length
            ) {
              this.$route.meta.breadcrumb = JSON.parse(
                JSON.stringify(constants.CONSUMER_ROUTE_META_BREADCRUMB)
              );

              if (
                response.data.newAncestor &&
                response.data.newAncestor.length &&
                response.data.newAncestor[0].type == "DEALER"
              ) {
                this.$route.meta.breadcrumb = JSON.parse(
                  JSON.stringify(constants.DEALER_ROUTE_META_BREADCRUMB)
                );
                if (
                  this.$route.meta.breadcrumb &&
                  this.$route.meta.breadcrumb.length >= 2
                ) {
                  this.$route.meta.breadcrumb[1].active = false;
                }
              }

              response.data.newAncestor.forEach((a, i) => {
                let active = false;
                if (
                  this.$route.meta.breadcrumb &&
                  this.$route.meta.breadcrumb.length >= 2
                ) {
                  this.$route.meta.breadcrumb[1].active = false;
                }
                if (this.$route.params.account_id == a.id) {
                  active = true;
                }
                this.$route.meta.breadcrumb.push({
                  text: a.name,
                  to: {
                    params: { account_id: a.id },
                    name: "sub_consumer"
                  },
                  active,
                  index: a.name + i
                });
                if (a.type.toLowerCase() === "dealer") {
                  this.$route.meta.breadcrumb[2].to = {
                    params: { account_id: a.id },
                    name: "sub_dealer"
                  };
                  this.$route.meta.breadcrumb.push({
                    text: "Breadcrumb_Consumer",
                    active: true,
                    index: "consumer",
                    to: {
                      params: { account_id: a.id },
                      name: "sub_dealer",
                      query: { tab: "consumer" }
                    },
                    active
                  });
                }
              });
            }
          }
          this.totalConsumer = response.data.pagination.total_records;
        } else if (response && response.error && response.error.message) {
          store.commit("mapLoader/SET_IS_BREADCRUMB_LOADING", false);
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        store.commit("mapLoader/SET_IS_BREADCRUMB_LOADING", false);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        this.show = false;
      }
    },
    handlePageChange(newPage) {
      this.updateQueryParam();
    },
    updateQueryParam() {
      const query = {
        ...this.$route.query,
        page: this.currentPage.toString()
      };
      if (this.filter && this.filter.value) {
        query.filter = this.filter.value;
      }
      this.$router
        .push({ name: this.$route.name, query: { ...query } })
        .catch(() => {});
      this.callGetConsumer();
    },
    redirectOnConsumerScreen() {
      const query = this.$route.params.account_id
        ? {
            query: { id: this.$route.params.account_id, tab: this.$route.name }
          }
        : "";

      this.$router.push({ name: "consumer-creation", ...query });
    },
    close(userData) {
      this.user = userData;
      this.$bvModal.show("modal-sm-remove");
    },
    onClose() {
      this.$bvModal.hide("modal-sm-remove");
    },
    userShow(e) {
      if (this.$route.meta.breadcrumb && this.$route.meta.breadcrumb.length) {
        if (this.$route.name === "sub_dealer") {
          const breadcrumbs = this.$route.meta.breadcrumb;
          const breadcrumbsConsumerLink =
            breadcrumbs[breadcrumbs.length - 1].to;
          breadcrumbs[breadcrumbs.length - 1].active = false;

          if (this.$route.name === "sub_dealer") {
            const alreadyAdded = this.$route.meta.breadcrumb.find(
              (e) => e.text === "Breadcrumb_Consumer"
            );
            if (!alreadyAdded.text) {
              this.$route.meta.breadcrumb.push({
                text: "Breadcrumb_Consumer",
                to: {
                  ...breadcrumbsConsumerLink,
                  Index: breadcrumbs[breadcrumbs.length - 1].text + "Consumer",
                  query: { tab: "consumer" }
                }
              });
            }
          }
          this.$route.meta.breadcrumb.push({
            text: e.name,
            to: {
              name: this.$route.name,
              params: this.$route.params,
              query: { tab: "consumer" }
            }
          });
        } else if (this.$route.name === "sub_consumer") {
          this.$route.meta.breadcrumb.forEach((e) => {
            e.active = false;
          });
          this.$route.meta.breadcrumb.push({
            text: e.name,
            to: { params: { account_id: e.id }, name: "sub_consumer" }
          });
          const isUserExists = this.$route.meta.breadcrumb.find(
            (e) => e.text === "Breadcrumb_Users"
          );
          if (!isUserExists || !isUserExists.text) {
            this.$route.meta.breadcrumb.push({
              text: "Breadcrumb_Users",
              active: true
            });
          }
          this.$router.push({
            name: this.$route.name,
            params: e.id,
            query: { tab: "users" }
          });
        } else if (this.$route.name === "consumer") {
          this.$route.meta.breadcrumb.forEach((e) => {
            e.active = false;
          });
          this.$route.meta.breadcrumb.push({
            text: e.name,
            to: { name: "consumer", query: { tab: "consumer" } }
          });
        }
      }
      this.userListShow(e);
      this.isUserShow = true;
      this.isUserConsumer = false;
      this.accountId = e;
      this.userAccountName = e.name;
    },
    consumerShow(e) {
      this.isUserConsumer = true;
      this.isUserShow = false;
    },
    async updateStatus(account, status) {
      try {
        this.show = true;
        let response = this.$route.params.account_id
          ? await new AccountService().updateConsumerSubAccountStatus({
              account_id: account.id,
              status: status,
              type: "CONSUMER"
            })
          : await new AccountService().updateConsumerAccountStatus({
              account_id: account.id,
              status: status,
              type: "CONSUMER"
            });
        if (response && response.data) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("SubAccounts.AccountStatustitle"),
              text: this.$t("SubAccounts.AccountStatusText"),
              icon: "EditIcon",
              variant: "success"
            }
          });
          this.getConsumer();
        } else if (response && response.error && response.error.message) {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.show = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.table-responsive {
  height: calc(100vh - 300px);
}
</style>
<style lang="scss" scoped>
.dealer-min-650 {
  // min-width: 650px;
  // min-height: 40vh;
  overflow-y: auto;
  height: calc(100vh - 300px) !important;
  .b-table {
    width: calc(100% - 18px) !important;
  }
}
.dealer-min-650-m {
  overflow-y: auto;
  height: calc(100vh - 270px) !important;
  .b-table {
    width: calc(100% - 18px) !important;
  }
}
@media screen and (max-width: 667px) {
  .con {
    .d-flex.w-100.justify-content-end {
      justify-content: flex-start !important;
    }
    .nav.nav-pills {
      margin-bottom: 0px;
    }
    .dealer-min-650-m {
      height: calc(100vh - 330px) !important;
    }
  }
}
</style>
